import React, { useEffect } from "react";

import { graphql } from "gatsby";
import { InView } from "react-intersection-observer";

import Showreel from "components/showreel";
import Grid from "components/grid";

const DefaultHomepage = (props) => {
  //PROPS
  const {
    data: {
      page: {
        homepage_page: { isLatestFirstHomepageModule, latestGrid, videoCover }
      }
    },
    pageContext: {
      language: { code }
    },
    skills,
    isBlack,
    setBlack,
    dimensions,
    locale,
    setLogo,
    setCookiesSpecial
  } = props;
  //PROPS

  //USEFFECT
  useEffect(() => {
    isLatestFirstHomepageModule ? setBlack(true) : setBlack(false);
    return () => {
      setBlack(true);
    };
  }, [setBlack]);

  useEffect(() => {
    let ticking = false;

    const updateScrollDir = () => {
      const scrollY = document.querySelector("#latest").getBoundingClientRect()
        .top;

      scrollY < 200 && scrollY > -200 ? setLogo(true) : setLogo(false);

      scrollY < window.innerHeight - 100
        ? setCookiesSpecial(true)
        : setCookiesSpecial(false);
      ticking = false;
      return;
    };

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir);
        ticking = true;
      }
    };

    //console.log("adicionar");
    window.addEventListener("scroll", onScroll, true);

    return () => {
      //console.log("saiu");
      window.removeEventListener("scroll", onScroll, true);
    };
  }, []);

  useEffect(() => {
    return () => {
      document.querySelector("#latestLi").classList.remove("active");
    };
  }, []);
  //USEFFECT

  return (
    <>
      <h1 className="abs_hide">Homepage Playground</h1>
      <main
        className={isLatestFirstHomepageModule ? "main" : "main main--nopad"}
      >
        {isLatestFirstHomepageModule ? (
          <>
            <h2 className="h1-underline">Latest</h2>
            <Grid
              locale={locale}
              data={latestGrid}
              hasFilter={false}
              type="home"
              dimensions={dimensions}
            />
            <InView
              as="div"
              onChange={(inView, entry) => {
                if (!inView) {
                  setBlack(true);
                  // document.querySelector("#latestLi")?.classList.add("active");
                  setLogo(false);
                } else {
                  setBlack(false);
                  setLogo(true);
                  // document
                  //   .querySelector("#latestLi")
                  //   ?.classList.remove("active");
                }
              }}
              threshold={0.1}
            >
              <Showreel
                videoCover={videoCover}
                code={code}
                skills={skills}
                isBlack={isBlack}
                setBlack={setBlack}
                isHome={true}
                dimensions={dimensions}
              />
            </InView>
          </>
        ) : (
          <>
            <InView
              as="div"
              onChange={(inView, entry) => {
                if (!inView) {
                  setBlack(true);
                  // document.querySelector("#latestLi")?.classList.add("active");
                } else {
                  setBlack(false);
                  setLogo(false);
                  // document
                  //   .querySelector("#latestLi")
                  //   ?.classList.remove("active");
                }
              }}
              threshold={0.1}
            >
              <Showreel
                isHome={true}
                videoCover={videoCover}
                code={code}
                skills={skills}
                isBlack={isBlack}
                setBlack={setBlack}
                first
                dimensions={dimensions}
              />
            </InView>

            <div className="latest" id="latest">
              <h2 className="h1 h1-underline h1-underline--nom">Latest</h2>
              <Grid
                locale={locale}
                data={latestGrid}
                hasFilter={false}
                type="home"
                dimensions={dimensions}
              />
            </div>
          </>
        )}
      </main>
    </>
  );
};

export const query = graphql`
  query Home($id: String) {
    page: wpPage(id: { eq: $id }) {
      title
      id
      seo {
        seoDescription
        seoTitle
        seoImage {
          localFile {
            url
          }
        }
      }
      homepage_page {
        videoCover {
          mediaItemUrl
        }
        isLatestFirstHomepageModule
        latestGrid {
          ... on WpPost {
            uri
            work_info {
              workType
              title
              shortDescription
              longDescription
              client
              vimeoLink
              availableOnTheSite
              coverImage {
                altText
                mediaDetails {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              coverVideo {
                mediaItemUrl
              }
            }
          }
          ... on WpNew {
            uri
            news_info {
              title
              shortDescription
              newsType
              client
              longDescription
              availableOnTheSite
              coverImage {
                altText
                mediaDetails {
                  height
                  width
                }
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 3600, quality: 100) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              coverVideo {
                mediaItemUrl
              }
            }
          }
        }
      }
    }
    allWpSkill {
      edges {
        node {
          slug
          name
          id
          language {
            code
          }
        }
      }
    }
  }
`;

export default DefaultHomepage;
